<template>
    <el-dialog 
        :modal-append-to-body="false" 
        title="登录欢迎语" 
        :visible.sync="dialogVisible" 
        width="50%"
        center
        @close="close"
    >
        <div class="cus_body">
            <el-form :inline="true">
                <el-form-item label="请输入常用语">
                    <el-input 
                        type="textarea"
                        size="medium"
                        :rows="6"
                        v-model="langContent"
                    ></el-input>
                    <div class="default" @click="restData">还原默认</div>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="load" @click="save">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { updataShareWelcome } from '@/api/companyManage.js';

    export default{
        data(){
            return {
                dialogVisible: false,
                cardId: '',
                companyId: '',
                companyName: '',
                name: '',
                langContent: '您好，欢迎访问我的名片。请问有什么可以帮到您的吗？',
                load: false,
            }
        },
        methods:{
            init(){
                this.getData();
                this.dialogVisible = true;
            },
            close(){
                this.dialogVisible = false;
            },
            restData(){
                this.langContent = '您好，欢迎访问我的名片。请问有什么可以帮到您的吗？';
            },
            getData(){
                let data = JSON.parse(localStorage.getItem('CARDINFO'));
                this.cardId = data.cardId;
                this.companyId = data.companyId;
                this.companyName = data.companyName;
                this.name = data.name;
                this.formatData();
            },
            formatData(){
                let data = localStorage.getItem('LOGINLANG');
                if(data){
                    this.langContent = data;
                }else{
                    this.langContent = '您好，欢迎访问我的名片。请问有什么可以帮到您的吗？';
                }
            },
            save(){
                if(!this.langContent){
                    this.$message.error('登录欢迎语不能为空！');
                    return;
                };
                let opts = {
                    myCardId: this.cardId,
                    hint: this.langContent
                };

                this.load = true;
                try{
                    updataShareWelcome(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            localStorage.setItem('LOGINLANG',this.langContent);
                            this.load = false;
                            this.$emit('success');
                            this.close();
                        }else{
                            this.$message.success(res.message);
                            this.load = false;
                        }
                    }).catch(err=>{
                        this.load = false;
                    })
                }catch(err){
                    this.load = false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.cus_body{
    width: 100%;
    .default{
        font-size: 14px;
        color: #51CBCD;
        margin-top: 14px;
        cursor: pointer;
    }
}
::v-deep{
    .el-form--inline .el-form-item__content{
        width: 500px;
    }
}
</style>