<template>
    <div class="commonfun_con">
        <el-tabs v-model="activeName">
            <el-tab-pane label="常用语设置" name="first">
                <div class="cus_con" v-loading="loading">
                    <div class="row d-flex">
                        <div class="title">分享语设置</div>
                        <div class="row_con">
                            <div class="val">{{shareLang}}</div>
                            <div class="edit" @click="changeShare">编辑分享语</div>
                        </div>
                    </div>
                    <div class="row d-flex">
                        <div class="title">欢迎语设置</div>
                        <div class="row_con">
                            <div class="val">{{welcomeLang}}</div>
                            <div class="edit" @click="changeWelcome">编辑欢迎语</div>
                        </div>
                    </div>
                    <div class="row d-flex">
                        <div class="title">用户常用语</div>
                        <div class="row_con">
                            <template v-if="userList.length">
                                <div class="val d-flex" v-for="(item,index) in userList" :key="index">
                                    <div class="txt">{{item.text}}</div>
                                    <div class="del delColor" @click="userDel(item.commonId,1)">删除</div>
                                    <div class="del" @click="userEdit(item)">编辑</div>
                                </div>
                            </template>
                            <div class="edit" @click="changeUser">新增常用语</div>
                        </div>
                    </div>
                    <div class="row d-flex">
                        <div class="title">CRM常用语</div>
                        <div class="row_con">
                            <template v-if="crmList.length">
                                <div class="val d-flex" v-for="(item,index) in crmList" :key="index">
                                    <div class="txt">{{item.text}}</div>
                                    <div class="del delColor" @click="crmDel(item.commonId,2)">删除</div>
                                    <div class="del" @click="crmEdit(item)">编辑</div>
                                </div>
                            </template>
                            <div class="edit" @click="changeCrm">新增CRM常用语</div>
                        </div>
                    </div>
                    <div class="row d-flex">
                        <div class="title">登录欢迎语</div>
                        <div class="row_con">
                            <div class="val">{{loginLang}}</div>
                            <div class="edit" @click="changeLogin">编辑登录欢迎语</div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

        <!-- 分享 欢迎 -->
        <sharelang-pop ref="sharelangPop" @success="updataShare" />
        <!-- 登录 -->
        <loginlang-pop ref="loginlangPop" @success="updataLogin" />
        <!-- crm user -->
        <crmuserlang-pop ref="crmuserlangPop" @success="updatalist" :userLen="userList.length" :crmLen="crmList.length" />
    </div>
</template>

<script>
    import sharelangPop from './components/sharelang';
    import loginlangPop from './components/loginlang';
    import crmuserlangPop from './components/crmuserlang';
    import { queryCardinfo,queryLang,delLang } from '@/api/companyManage.js';

    export default {
        components:{
            sharelangPop,
            loginlangPop,
            crmuserlangPop
        },
        data(){
            return {
                activeName: 'first',
                cardId: '',
                companyId: '',
                companyName: '',
                name: '',
                shareLang: '',
                welcomeLang: '',
                loginLang: '',
                userList: [],
                crmList: [],
                loading: false,
            }
        },
        methods:{
            changeShare(){
                this.$refs.sharelangPop.init('share');
            },
            changeWelcome(){
                this.$refs.sharelangPop.init('welcome');
            },
            changeLogin(){
                this.$refs.loginlangPop.init();
            },
            changeUser(){
                this.$refs.crmuserlangPop.init('user','add');
            },
            userEdit(item){
                this.$refs.crmuserlangPop.init('user','edit',item);
            },
            changeCrm(){
                this.$refs.crmuserlangPop.init('crm','add');
            },
            crmEdit(item){
                this.$refs.crmuserlangPop.init('crm','edit',item);
            },
            userDel(id,type){
                this.dellist(id,type);
            },
            crmDel(id,type){
                this.dellist(id,type);
            },
            dellist(commonId,type){
                let opts = {
                    commonId,
                    type
                };
                delLang(opts).then(res=>{
                    if(res.code == 200){
                        this.$message.success(res.message);
                        if(type == 1){
                            this.getlist(1); //用户
                        }else{
                            this.getlist(2); //crm
                        }
                    }else{
                        this.$message.success(res.message);
                    }
                })
            },
            getData(){
                let opts = {
                    userId: localStorage.getItem('userId')
                };
                this.loading = true;
                try{
                    queryCardinfo(opts).then(res=>{
                        if(res.code == 200){
                            this.cardId = res.data.cardId;
                            this.companyId = res.data.companyId;
                            this.companyName = res.data.companyName;
                            this.name = res.data.name;
                            let obj = {
                                cardId: res.data.cardId,
                                companyId: res.data.companyId,
                                companyName: res.data.companyName,
                                name: res.data.name,
                            }
                            localStorage.setItem('CARDINFO',JSON.stringify(obj));
                            res.data.shareSpeech && localStorage.setItem('SHARELANG',res.data.shareSpeech);
                            res.data.welcomeSpeech && localStorage.setItem('WELCOMALANG',res.data.welcomeSpeech);
                            res.data.hint && localStorage.setItem('LOGINLANG',res.data.hint);

                            this.getShare();
                            this.getWelcome();
                            this.getLogin();

                            this.loading = false;
                        }else{
                            this.loading = false;
                        }
                    })
                }catch(err){
                    this.loading = false;
                }
            },
            getShare(){
                let data = localStorage.getItem('SHARELANG');
                
                if(data){
                    this.shareLang = data.replace('#name#', this.name).replace('#company#', this.companyName);
                }else{
                    this.shareLang = `您好，这是${this.companyName}的${this.name}，这是我的电子名片，请惠存。`;
                }
            },
            getWelcome(){
                let data = localStorage.getItem('WELCOMALANG');
                if(data){
                    this.welcomeLang = data.replace('#name#', this.name).replace('#company#', this.companyName);
                }else{
                    this.welcomeLang = `您好，这是${this.companyName}的${this.name}，欢迎进入我的名片，有什么可以帮你的吗？你可以在这里跟我试试沟通`;
                }
            },
            getLogin(){
                let data = localStorage.getItem('LOGINLANG');
                if(data){
                    this.loginLang = data;
                }else{
                    this.loginLang = '您好，欢迎访问我的名片。请问有什么可以帮到您的吗？';
                }
            },
            updataShare(){
                this.getShare();
                this.getWelcome();
            },
            updataLogin(){
                this.getLogin();
            },
            getlist(type){
                let opts = { type };
                queryLang(opts).then(res=>{
                    if(res.code == 200){
                        if(res.data && res.data.length){
                            if(type == 1){
                                this.userList = res.data;
                            } else{
                                this.crmList = res.data;
                            }      
                        }
                    }else{
                        if(type == 1){
                            this.userList = [];
                        } else{
                            this.crmList = [];
                        } 
                    }
                })
            },
            updatalist(){
                this.getlangList();
            },
            getlangList(){
                this.getlist(1); //用户
                this.getlist(2); //crm
            },
        },
        mounted(){
            this.getData();
            this.getlangList();
        }
    }
</script>

<style lang="scss" scoped>
    .commonfun_con{
        width: 100%;
        min-height: 100%;
        background: #fff;
        .cus_con{
            width: 100%;
            padding: 24px;
            .row{
                width: 100%;
                margin-bottom: 32px;
                .title{
                    min-width: 120px;
                    font-size: 14px;
                    color: #606266;
                }
                .row_con{
                    flex: 1;
                    .val{
                        font-size: 14px;
                        color: #606266;
                        margin-bottom: 12px;
                        align-items: center;
                        .txt{
                            width: 500px;
                        }
                        .del{
                            margin-left: 12px;
                            color: #51CBCD;
                            cursor: pointer;
                        }
                    }
                    .edit{
                        font-size: 14px;
                        color: #51CBCD;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>