<template>
    <el-dialog 
        :modal-append-to-body="false" 
        :title="type == 'share' ? '分享语设置' : '欢迎语设置'" 
        :visible.sync="dialogVisible" 
        width="50%"
        center
        @close="close"
    >
        <div class="cus_body">
            <el-input 
                type="textarea"
                size="medium"
                :rows="6"
                v-model="langContent"
            >
            </el-input>
            <div class="default" @click="restData">还原默认</div>
            <template v-if="type == 'share'">
                <ul class="list_con" v-if="templateList.length">
                    <li class="item_row d-flex" v-for="(item,index) in templateList" :key="index">
                        <div class="row_l">
                            <div class="title">{{item.title}}</div>
                            <div class="val">{{item.desc}}</div>
                        </div>
                        <div class="row_r" @click="user(item)">点击使用</div>
                    </li>
                </ul>
            </template>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="load" @click="save">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { updataShareWelcome } from '@/api/companyManage.js';

    export default{
        data(){
            return {
                dialogVisible: false,
                type:'share',
                templateList: [],
                cardId: '',
                companyId: '',
                companyName: '',
                name: '',
                langContent: '',
                load: false,
            }
        },
        methods:{
            init(type){
                this.type = type;
                this.getData();
                this.dialogVisible = true;
            },
            close(){
                this.dialogVisible = false;
            },
            user(item){
                this.langContent = item.desc;
            },
            restData(){
                if(this.type == 'share'){
                    this.langContent = this.templateList[0].desc;
                }else{
                    this.langContent = `您好，这是${this.companyName}的${this.name}，欢迎进入我的名片，有什么可以帮你的吗？你可以在这里跟我试试沟通`;
                }
            },
            getData(){
                let data = JSON.parse(localStorage.getItem('CARDINFO'));
                this.cardId = data.cardId;
                this.companyId = data.companyId;
                this.companyName = data.companyName;
                this.name = data.name;
                this.formatData();
            },
            formatData(){
                if(this.type == 'share'){
                    this.templateList = [
                        {
                            title: '商务通用',
                            desc: `您好，这是${this.companyName}的${this.name}，这是我的电子名片，请惠存。`
                        },
                        {
                            title: '持之以恒',
                            desc: `贵有恒,何必三更起五更眠;最无益,只怕一日塌十日寒。我是${this.name},认识一下~`
                        },
                        {
                            title: '豪迈大气',
                            desc: `时人莫小池中水,浅处不妨有卧龙。我是${this.name},认识一下~`
                        },
                        {
                            title: '诗意婉约',
                            desc: `茫茫人海相遇即是缘分。我是${this.name}，认识一下吧~`
                        },
                        {
                            title: '可爱俏皮',
                            desc: `大家好,我是${this.companyName}的${this.name},请大家多多关照。`
                        }
                    ];

                    let data = localStorage.getItem('SHARELANG');
                    if(data){
                        this.langContent = data.replace('#name#', this.name).replace('#company#', this.companyName);
                    }else{
                        this.langContent = this.templateList[0].desc;
                    }
                }else{
                    let data = localStorage.getItem('WELCOMALANG');
                    if(data){
                        this.langContent = data.replace('#name#', this.name).replace('#company#', this.companyName);
                    }else{
                        this.langContent = `您好，这是${this.companyName}的${this.name}，欢迎进入我的名片，有什么可以帮你的吗？你可以在这里跟我试试沟通`;
                    }
                }
            },
            save(){
                if(!this.langContent){
                    let lang = this.type == 'share' ? '分享语不能为空！' : '欢迎语语不能为空！'
                    this.$message.error(lang);
                    return;
                };
                let opts = {
                    myCardId: this.cardId
                };
                let shareSpeech,welcomeSpeech;
                if(this.type == 'share'){
                    shareSpeech = this.langContent.replace(this.companyName, '#company#').replace(this.name, '#name#');
                    opts.shareSpeech = shareSpeech;
                }else{
                    welcomeSpeech = this.langContent.replace(this.companyName, '#company#').replace(this.name, '#name#');
                    opts.welcomeSpeech = welcomeSpeech;
                };

                this.load = true;
                try{
                    updataShareWelcome(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            if(this.type == 'share'){
                                localStorage.setItem('SHARELANG',shareSpeech);
                            }else{
                                localStorage.setItem('WELCOMALANG',welcomeSpeech);
                            };
                            this.load = false;
                            this.$emit('success');
                            this.close();
                        }else{
                            this.$message.success(res.message);
                            this.load = false;
                        }
                    }).catch(err=>{
                        this.load = false;
                    })
                }catch(err){
                    this.load = false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.cus_body{
    width: 100%;
    .default{
        font-size: 14px;
        color: #51CBCD;
        margin-top: 14px;
        cursor: pointer;
    }
    .list_con{
        width: 100%;
        .item_row{
            width: 100%;
            margin-top: 28px;
            justify-content: space-between;
            align-items: center;
            .row_l{
                width: calc(100% - 100px);
                .title{
                    font-size: 16px;
                    color:#000;
                    font-weight: bold;
                }
                .val{
                    font-size: 14px;
                    color: #606266;
                    margin-top: 12px;
                }
            }
            .row_r{
                font-size: 14px;
                color: #51CBCD;
                cursor: pointer;
            }
        }
    }
}
</style>