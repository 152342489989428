<template>
    <el-dialog 
        :modal-append-to-body="false" 
        :title="type == 'user' ? '常用语设置' : 'CRM常用语设置'" 
        :visible.sync="dialogVisible" 
        width="50%"
        center
        @close="close"
    >
        <div class="cus_body">
            <el-form :inline="true">
                <el-form-item label="请输入常用语">
                    <el-input 
                        class="mar_b"
                        type="textarea"
                        size="medium"
                        :rows="6"
                        v-model="text"
                    ></el-input>
                    <div class="d-flex">
                        <!-- <el-button type="primary">新增常用语</el-button> -->
                        <div class="desc">提示：最多可以添加12条话术</div>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="load" @click="save">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { addLang } from '@/api/companyManage.js';

    export default{
        props:{
            userLen: Number,
            crmLen: Number
        },
        data(){
            return {
                dialogVisible: false,
                type:'user',
                fun: 'add',
                text: '',
                load: false,
                commonId: '',
            }
        },
        methods:{
            init(type,fun,data){
                this.type = type;
                this.fun = fun;
                if(fun == 'edit'){
                    this.commonId = data.commonId;
                    this.text = data.text;
                }
                this.dialogVisible = true;
            },
            close(){
                this.text = '';
                this.dialogVisible = false;
            },
            save(){
                if(this.type == 'user'){
                    if(this.userLen >= 12){
                        this.$message.error('最多可以添加12条话术');
                        return;
                    };
                    if(!this.text){
                        this.$message.error('常用语不能为空！');
                        return;
                    };
                };
                if(this.type == 'crm'){
                    if(this.crmLen >= 12){
                        this.$message.error('最多可以添加12条话术');
                        return;
                    };
                    if(!this.text){
                        this.$message.error('CRM常用语不能为空！');
                        return;
                    };
                };
                let type = this.type == 'user' ? 1 : 2;
                let opts = {
                    text: this.text,
                    type, 
                };
                if(this.fun == 'edit'){
                    opts.commonId = this.commonId;
                };
                this.load = true;
                try{
                    addLang(opts).then(res=>{
                        if(res.code == 200){
                            this.$message.success(res.message);
                            this.$emit('success');
                            this.load = false;
                            this.close();
                        }else{
                            this.$message.success(res.message);
                            this.load = false;
                        }
                    }).catch(err=>{
                        this.load = false;
                    })
                }catch(err){
                    this.load = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.cus_body{
    width: 100%;
}
.mar_b{
    margin-bottom: 12px;
}
.desc{
    font-size: 14px;
    color: #AEB5BD;
    margin-left: 12px;
}
::v-deep{
    .el-form--inline .el-form-item__content{
        width: 500px;
    }
}
</style>